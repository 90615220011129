import React from "react"
import PropTypes from "prop-types"
// nodejs library to set properties for components
import { PayPalButton } from "react-paypal-button-v2"

export default function Paypal(props) {
  const {
    handleNext,
    handleSubmit,
    setPaymentName,
    setPaymentCompleted,
    setPaymentError,
  } = props
  return (
    <PayPalButton
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: "USD",
                value: "20.00",
              },
            },
          ],
          application_context: {
            shipping_preference: "NO_SHIPPING", // default is "GET_FROM_FILE"
          },
        })
      }}
      onApprove={(data, actions) => {
        // Capture the funds from the transaction
        return actions.order.capture().then(function (details) {
          setPaymentName(details.payer.name.given_name)
          setPaymentCompleted(true)
          handleSubmit()
          handleNext()
          // OPTIONAL: Call your server to save the transaction
          // return fetch("/paypal-transaction-complete", {
          //   method: "post",
          //   body: JSON.stringify({
          //     orderID: data.orderID,
          //   }),
          // })
        })
      }}
      options={{
        clientId:
          "AadZxhydmCMyP9XAR34UMi1wWzZdbAgLtcxEeuUKcwUyfgoW-R3I3XHTCdyCdmdij-zRat8nUgqRuV-d",
      }}
      onError={() => {
        setPaymentError(true)
      }}
    />
  )
}

Paypal.propTypes = {
  handleNext: PropTypes.func,
  handleSubmit: PropTypes.func,
  setPaymentName: PropTypes.func,
  setPaymentCompleted: PropTypes.func,
  setPaymentError: PropTypes.func,
}
