import React from "react"
import PropTypes from "prop-types"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Payment from "components/Payment/Payment"

// page styles
import onlinePaymentStyle from "assets/jss/material-kit-pro-react/views/hotPepperEatingRegistrationPageSections/hotPepperEatingPaymentStyle.jsx"
import HotPepperEatingPaymentForm from "../../../components/HotPepperEatingRegistrationForm/HotPepperEatingPaymentForm"
const useStyles = makeStyles(onlinePaymentStyle)

export default function HotPepperEatingPayment(props) {
  const {
    handleBack,
    handleNext,
    handleSubmit,
    setPaymentName,
    setPaymentCompleted,
    paymentError,
    setPaymentError,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12}>
            <h2 className={classes.title}>Pay Now to Submit Registration</h2>
            <HotPepperEatingPaymentForm
              id="payment"
              name="payment"
              type="payment"
              handleBack={handleBack}
              handleNext={handleNext}
              handleSubmit={handleSubmit}
              setPaymentName={setPaymentName}
              setPaymentCompleted={setPaymentCompleted}
              paymentError={paymentError}
              setPaymentError={setPaymentError}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}

HotPepperEatingPayment.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  handleSubmit: PropTypes.func,
  setPaymentName: PropTypes.func,
  setPaymentCompleted: PropTypes.func,
  paymentError: PropTypes.bool,
  setPaymentError: PropTypes.func,
}
