import {
  dangerColor,
  description,
  textCenter,
  title,
} from "assets/jss/material-kit-pro-react.jsx"
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx"

const paymentStyle = (theme) => ({
  ...modalStyle(theme),
  cooksScheduleButton: {
    margin: "0 auto",
  },
  cardIcon: {
    fontSize: "1rem",
    marginRight: "3px",
    "& path": {
      color: "#e4240ecc",
    },
  },
  checkContainer: {
    ...textCenter,
    marginBottom: "1rem",
    "& p": {
      ...description,
    },
  },
  closed: {
    ...description,
    ...textCenter,
    color: dangerColor[0],
  },
  modalCooksScheduleMapTitle: {
    ...title,
    lineHeight: "1.5",
    ...textCenter,
  },
  modalCooksScheduleMapImage: {
    maxWidth: "100%",
    margin: "20px auto 50px auto",
    borderRadius: "6px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  },
  modalCooksScheduleMapFooter: {
    padding: "15px",
    textAlign: "right",
    margin: "0",
    justifyContent: "center",
    borderTop: "1px solid #ccc",
    paddingTop: "15px",
  },
  modalCardCategory: {
    textTransform: "uppercase",
    color: "#377358",
    display: "flex",
    alignItems: "center",
    margin: "0",
    "& path": {
      color: "#377358 !important",
    },
  },
  paymentContainer: {
    ...textCenter,
    "& p": {
      ...description,
      marginBottom: "1rem",
    },
  },
  paymentSuccess: {
    ...textCenter,
    "& p": {
      textAlign: "left !important",
    },
    "& li": {
      textAlign: "left !important",
    },
  },
  paymentError: {
    "& p": {
      ...description,
    },
    "& li": {
      ...description,
    },
  },
  paymentErrorIcon: {
    color: dangerColor[0],
    fontSize: "3rem",
    marginTop: "30px",
  },
  paymentTitle: {
    ...title,
    fontWeight: "400",
    marginBottom: "10px",
    marginTop: "0px",
  },
  price: {
    ...title,
    ...textCenter,
    marginTop: "0",
    fontWeight: "800",
    fontSize: "2rem",
  },
  submitButton: {
    ...textCenter,
    marginTop: "1rem",
  },
})

export default paymentStyle
