// gatsby libraries
import React from "react"
import { Link } from "gatsby"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui/icons
import ChevronRight from "@material-ui/icons/ChevronRight"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ErrorIcon from "@material-ui/icons/Error"
// page styles
import paymentStyle from "assets/jss/material-kit-pro-react/components/paymentStyle"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Paypal from "components/Paypal/Paypal.jsx"
import PropTypes from "prop-types"

const useStyles = makeStyles(paymentStyle)

export default function HotPepperEatingPaymentForm(props) {
  const {
    handleBack,
    handleNext,
    handleSubmit,
    setPaymentName,
    setPaymentCompleted,
    paymentError,
    setPaymentError,
  } = props

  const handleClick = () => {
    handleBack()
  }

  const classes = useStyles()
  return (
    <>
      <GridContainer justifyContent="center" className={classes.checkContainer}>
        <GridItem sm={12} md={10} lg={8}>
          <p>
            If you would like to change any registrations details before payment
            click below.
          </p>
          <Button size="sm" color="primary" onClick={handleClick}>
            <ChevronLeft />
            Back to Details
          </Button>
        </GridItem>
      </GridContainer>
      <h3 className={classes.modalCooksScheduleMapTitle}>
        Hot Pepper Eating Contest Payment
      </h3>
      <GridContainer
        justifyContent="center"
        className={classes.paymentContainer}
      >
        <GridItem sm={12} md={10} lg={8}>
          <p>
            In order to compete in the Chilimania Hot Pepper Eating Contest, you
            must pay to register. The registration fee for the Chilimania Hot
            Pepper Eating Contest is:
          </p>
          <h4 className={classes.price}>$20</h4>
          <p>
            Once payment is complete, your registration will be automatically
            submitted. Please double check all registration information before
            payment.
          </p>
          <p>
            Please select one of the payment options below to pay for your
            Chilimania Hot Pepper Eating Contest registration.
          </p>
          <Paypal
            handleNext={handleNext}
            handleSubmit={handleSubmit}
            setPaymentName={setPaymentName}
            setPaymentCompleted={setPaymentCompleted}
            setPaymentError={setPaymentError}
          />
        </GridItem>
      </GridContainer>
      {paymentError && (
        <GridContainer
          justifyContent="center"
          className={classes.errorContainer}
        >
          <GridItem sm={12} md={10} lg={8}>
            <div className={classes.paymentError}>
              <ErrorIcon className={classes.paymentErrorIcon} />
              <h3 className={classes.paymentTitle}>We're Sorry.</h3>
              <p>
                <b>We were unable to complete your payment.</b>
                <br />
              </p>
              <p>Possible Reasons Include:</p>
              <ul>
                <li>Paypal services are down.</li>
                <li>Your Paypal account is not properly configured.</li>
                <li>Your card was unable to be processed successfully.</li>
              </ul>
              <p>
                To futher discuss registration details, refunds, or issues with
                payment, please use the contact email listed below.
              </p>
              <ul>
                <li>
                  Contact:{" "}
                  <a href="mailto:cookoff@chilimania.com">
                    cookoff@chilimania.com
                  </a>{" "}
                </li>
              </ul>
              <GridContainer justifyContent="center">
                <GridItem xs={12} className={classes.submitButton}>
                  <Link to="/hot-pepper-eating-contest">
                    <Button color="primary" onClick={handleClick}>
                      Return to Website
                      <ChevronRight />
                    </Button>
                  </Link>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
      )}
    </>
  )
}

HotPepperEatingPaymentForm.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  handleSubmit: PropTypes.func,
  setPaymentName: PropTypes.func,
  setPaymentCompleted: PropTypes.func,
  paymentError: PropTypes.bool,
  setPaymentError: PropTypes.func,
}
